import { usePreference, useSetPreference } from 'hooks'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { twMerge } from 'tailwind-merge'

interface IProps {
  calloutId: string
  type: string
  persistent?: boolean
  showHeader?: boolean
  fill?: boolean
  children?: React.ReactNode
}

const BaseCallout: React.FC<IProps> = ({
  calloutId,
  type,
  persistent = false,
  children,
  showHeader = true,
  fill = false
}) => {
  const setPreference = useSetPreference()
  const hideCallout = usePreference(`hideCallout:${calloutId}`, false)
  const finishedCallout = usePreference(`finishedCallout:${calloutId}`, false)

  const onHideClick = () => setPreference(`hideCallout:${calloutId}`, true)

  if (hideCallout) {
    return null
  }

  return (
    <div
      className={twMerge(
        'relative flex flex-col flex-1 shadow bg-white rounded-lg overflow-auto',
        !fill && 'relative p-[15px]'
      )}
    >
      {!persistent && (
        <div
          className={'absolute top-10 right-10 cursor-pointer'}
          onClick={onHideClick}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className={'text-gray-500 text-18 hover:text-gray-700'}
          />
        </div>
      )}

      {showHeader && (
        <Typography
          size="sm"
          color="darkGray"
          fontWeight="medium"
          className="text-center"
        >{`Learn \u00b7 ${type}`}</Typography>
      )}

      {showHeader ? (
        <div className="mt-[15px] w-full">{children}</div>
      ) : (
        children
      )}

      {finishedCallout && (
        <Typography
          className={'pt-12 text-sm font-medium text-gray-500 text-left'}
        >
          <FontAwesomeIcon icon={faCheck} />
          {` You've watched this module`}
        </Typography>
      )}
    </div>
  )
}

export default BaseCallout
