import {
  FilterableEngagementField,
  SortableEngagementField,
  SortDirection,
  useDiscoverEngagementsQuery
} from 'graphql/generated'
import React from 'react'
import { noop } from 'lodash'
import EngagementListItem from 'components/EngagementListItem'
import language from 'services/Language'
import { useEventContext } from 'context/EventContext'
import { GreyBoxHover } from 'components/common/StyledBoxes'
import { twMerge } from 'tailwind-merge'
import Button from 'components/Button'
import { Typography } from 'components/common'

interface IProps {
  showMoreButtons?: boolean
  initialLoadCount?: number
  loadMoreCount?: number
  direction?: 'verticle' | 'horizontal'
  fitParent?: boolean
  limitTitleText?: boolean
  showRsvp?: boolean
  hideAttendees?: boolean
}

export const UserRecomendations: React.FC<IProps> = ({
  showMoreButtons = true,
  initialLoadCount = 3,
  loadMoreCount = 6,
  direction = 'verticle',
  fitParent = false,
  limitTitleText = false,
  showRsvp = false
}) => {
  const { event } = useEventContext()

  const {
    data: eventsData,
    loading: eventsLoading,
    networkStatus: eventsNetworkStatus,
    fetchMore: fetchMoreEvents
  } = useDiscoverEngagementsQuery({
    variables: {
      ignoreEventId: event?.id,
      engagementConnection: { first: initialLoadCount },
      sort: {
        direction: SortDirection.DESCENDING,
        field: SortableEngagementField.START_TIME
      },
      filters: [
        FilterableEngagementField.STATUS_NOT_ENDED,
        FilterableEngagementField.TYPE_SOCIAL
      ]
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: noop
  })

  const eventsLoadingMore = eventsNetworkStatus === 3
  const pageInfoEvents = eventsData?.discoverEngagements?.pageInfo

  const loadMoreEvents = () => {
    if (!pageInfoEvents || !pageInfoEvents.hasNextPage) {
      return
    } else if (eventsLoading || eventsLoadingMore) {
      return
    }

    fetchMoreEvents({
      variables: {
        engagementConnection: {
          first: loadMoreCount,
          after: pageInfoEvents.endCursor
        },
        sort: {
          direction: SortDirection.DESCENDING,
          field: SortableEngagementField.START_TIME
        },
        filters: [
          FilterableEngagementField.STATUS_NOT_ENDED,
          FilterableEngagementField.TYPE_SOCIAL
        ]
      }
    })
  }

  const events = eventsData?.discoverEngagements?.edges

  return (
    <>
      {events && (
        <div>
          <div className="mx-2">
            <Typography>Upcoming Events</Typography>
          </div>

          <div
            className={twMerge(
              'flex gap-2 overflow-auto my-2 py-2 flex-row',
              direction === 'verticle' ? 'sm:flex-col' : 'sm:flex-row'
            )}
          >
            {events.map(event =>
              event.node.__typename === 'Event' ? (
                <GreyBoxHover
                  key={`event_${event.node.id}`}
                  className={twMerge(
                    'p-4 bg-[#fff] rounded-xl flex-none w-[250px] min-w-[240px]',
                    direction === 'verticle'
                      ? 'md:w-full mx-0'
                      : 'shadow-small mx-2',
                    fitParent && 'sm:flex-1'
                  )}
                >
                  <EngagementListItem
                    engagement={event.node}
                    limitTitleText={limitTitleText}
                    showRsvp={showRsvp}
                  />
                </GreyBoxHover>
              ) : null
            )}

            {showMoreButtons && pageInfoEvents?.hasNextPage && (
              <div className="w-full text-center">
                <Button
                  onClick={loadMoreEvents}
                  color="link"
                  size="sm"
                  loading={eventsLoading || eventsLoadingMore}
                  label={language.common.showMore}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {!events?.length && !eventsLoading && (
        <div className="pb-4">
          <Typography color="gray">{language.events.noEventsFound}</Typography>
        </div>
      )}
    </>
  )
}
