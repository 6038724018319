import { inflect } from 'inflection'
import moment from 'moment'
import {
  Event,
  EventFieldsFragment,
  EventRegistrationRSVP,
  EventState
} from 'graphql/generated'
import Thumbnail from 'components/Thumbnail'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { useReportContentModal } from 'components/modals/ReportContentModal'
import EventShareButton from 'components/shared/ShareButton/EventShareButton'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { useState } from 'react'
import { EventInputModal } from 'components/events/EventInputModal/EventInputModal'

interface IProps {
  event: EventFieldsFragment
  index: number
  showMenu?: boolean
  showSharing?: boolean
  href?: string
  showCollective?: boolean
}

const gradients = [
  ['#DD5E89', '#F7BB97'],
  ['#00d2ff', '#3a7bd5'],
  ['#EC6EAD', '#3494E6'],
  ['#43cea2', '#185a9d'],
  ['#B993D6', '#8CA6DB']
]

const EventListItem: React.FC<IProps> = ({
  event,
  index,
  showMenu = true,
  showSharing = true,
  showCollective = false
}) => {
  const gradient = gradients[index % gradients.length]
  const gradientStyle = {
    background: `linear-gradient(90deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`
  }

  const [showEventModal, setShowEventModal] = useState(false)
  const reportContent = useReportContentModal()

  return (
    <Link href={event.publicUrl}>
      <div className="flex flex-row">
        <div className="flex flex-1 space-x-2 px-2 py-4 hover:bg-gray-50">
          <div
            style={gradientStyle}
            className="w-16 h-16 rounded-lg flex flex-col items-center justify-center"
          >
            <span className="block text-white text-xs font-bold text-center">
              {moment(event.startTime).format('ddd')}
            </span>
            <span className="block text-white text-xs font-light text-center">
              {moment(event.startTime).format('MMM Do')}
            </span>
            <span className="block text-white text-xs font-light text-center">
              {moment(event.startTime).format('LT')}
            </span>
          </div>

          <div className="flex-1 space-y-1">
            <p className="text-xs font-bold text-text-primary leading-tight">
              {event.title}
            </p>

            {showCollective && event.collective && (
              <p
                translate="no"
                className="text-xs font-medium text-gray-700 leading-tight"
              >
                {event.collective.name}
              </p>
            )}

            <p className="text-xs font-light text-text-secondary leading-tight">
              {`${event.counts.attending} ${inflect(
                'attendees',
                event.counts.attending
              )}`}
              {` \u00b7 `}
              {event.formatLabel}
            </p>

            {event.rsvp === EventRegistrationRSVP.PENDING && (
              <p className="text-text-secondary text-xs font-light">
                <FontAwesomeIcon icon={faCheck} className="mr-1" />
                {'RSVP pending approval'}
              </p>
            )}

            {event.rsvp === EventRegistrationRSVP.YES && event.waitlisted && (
              <p className="text-text-secondary text-xs font-light">
                <FontAwesomeIcon icon={faCheck} className="mr-1" />
                {event.state !== EventState.ENDED
                  ? 'You are waitlisted'
                  : 'You were waitlisted'}
              </p>
            )}

            {event.rsvp === EventRegistrationRSVP.YES && !event.waitlisted && (
              <p className="text-blue text-xs font-light">
                <FontAwesomeIcon icon={faCheck} className="mr-1" />
                {event.state !== EventState.ENDED
                  ? 'You are attending'
                  : 'You attended'}
              </p>
            )}

            {event.speakers.length > 0 ? (
              <div className="pt-3">
                <p className="text-xs font-light text-text-secondary leading-tight pb-1">{`${inflect(
                  'Speaker',
                  event.speakers.length
                )}:`}</p>

                <div className="space-y-3">
                  {event.speakers.map(speaker => (
                    <div
                      key={`speaker-${speaker.id}`}
                      className="flex items-center space-x-2"
                    >
                      <Thumbnail
                        src={speaker.thumb}
                        className={
                          'h-8 w-8 rounded-full overflow-hidden shrink-0'
                        }
                      />

                      <div>
                        <span className="block text-xs font-bold">
                          {speaker.displayName}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <p className="text-xs font-light text-text-secondary leading-tight pb-1">
                  {`Hosted by `}{' '}
                  <span className="font-medium">{event.user.displayName}</span>
                </p>
              </div>
            ) : (
              <div className="inline-flex flex-row items-center space-x-2 pt-1">
                <p className="text-xs font-light text-gray-500 leading-tight">
                  {`Hosted by: `}
                </p>

                <Thumbnail
                  src={event.user.thumb}
                  className="h-6 w-6 rounded-full overflow-hidden"
                />

                <p className="text-xs font-light text-gray-500">
                  {event.user.displayName}
                </p>
              </div>
            )}
          </div>

          {(showSharing || showMenu) && (
            <div className="flex flex-row space-x-2">
              {showSharing && (
                <div>
                  <EventShareButton event={event} size="sm" rounded="full" />
                </div>
              )}

              {showMenu && (
                <DropdownMenu
                  items={[
                    event.canEdit &&
                      event.isPrivileged && {
                        label: 'Edit Event',
                        onClick: () => setShowEventModal(true)
                      },
                    {
                      label: 'Report Event',
                      onClick: () => {
                        reportContent(event as Event)
                      }
                    }
                  ]}
                />
              )}
            </div>
          )}
        </div>

        {showEventModal && (
          <EventInputModal
            event={event}
            collective={event.collective}
            onClose={() => setShowEventModal(false)}
          />
        )}
      </div>
    </Link>
  )
}

export default EventListItem
