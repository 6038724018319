import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export type BoxProps = Pick<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className' | 'ref' | 'onClick'
> & {
  children?: React.ReactNode
}

export const BoxHover: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <div {...rest} className={twMerge('cursor-pointer', rest.className)}>
      {children}
    </div>
  )
}

export const FlexBoxHover: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
      className={twMerge('flex flex-1 cursor-pointer', rest.className)}
    >
      {children}
    </div>
  )
}

export const GreyBoxHover: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <BoxHover
      {...rest}
      className={twMerge('hover:bg-gray-100 rounded-md', rest.className)}
    >
      {children}
    </BoxHover>
  )
}

export const GreyFlexBoxHover: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <FlexBoxHover
      {...rest}
      className={twMerge(
        'flex flex-1 hover:bg-gray-100 rounded-md',
        rest.className
      )}
    >
      {children}
    </FlexBoxHover>
  )
}
