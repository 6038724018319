import { useQueryErrorHandler } from 'hooks'
import { itemsPerPage } from 'lib/config'
import {
  FilterableEngagementField,
  SortDirection,
  SortableEngagementField,
  useCollectiveEngagementsQuery,
  CollectiveEngagementsQueryVariables
} from 'graphql/generated'
import { useMemo, useState } from 'react'

function useCollectiveEngagements(
  collectiveId: Maybe<string>,
  initialFilters: FilterableEngagementField[] = [],
  initialLoadSize: number = itemsPerPage
) {
  const onError = useQueryErrorHandler(
    'Failed to load engagements. Please try again later.'
  )
  const [filters, setFilters] =
    useState<FilterableEngagementField[]>(initialFilters)

  const appliedFiltersCount = useMemo(
    () =>
      filters.filter(
        f =>
          ![
            FilterableEngagementField.TYPE_SOCIAL,
            FilterableEngagementField.STATUS_NOT_ENDED
          ].includes(f)
      ).length,
    [filters]
  )

  const defaultVariables: CollectiveEngagementsQueryVariables = {
    collectiveId: collectiveId!,
    engagementConnection: {
      first: 0
    },
    sort: {
      direction: filters.includes(FilterableEngagementField.STATUS_ENDED)
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING,
      field: SortableEngagementField.START_TIME
    },
    filters
  }

  const { loading, data, networkStatus, fetchMore } =
    useCollectiveEngagementsQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        ...defaultVariables,
        collectiveId: collectiveId!,
        engagementConnection: {
          first: initialLoadSize
        }
      },
      skip: !collectiveId,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError
    })
  const edges = data?.collective?.engagements?.edges
  const pageInfo = data?.collective?.engagements?.pageInfo
  const loadingMore = networkStatus === 3

  const loadMore = () => {
    if (!pageInfo || !pageInfo.hasNextPage) {
      return
    } else if (loading || loadingMore) {
      return
    }

    fetchMore({
      variables: {
        ...defaultVariables,
        engagementConnection: {
          first: itemsPerPage,
          after: pageInfo.endCursor
        }
      }
    })
  }

  return {
    loading,
    loadingMore,
    networkStatus,
    loadMore,
    edges,
    pageInfo,
    filters,
    setFilters,
    appliedFiltersCount
  }
}

export default useCollectiveEngagements
