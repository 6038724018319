import { EventReminderProvider } from 'context/EventReminderContext'
import EmailVerificationBanner from './banners/EmailVerificationBanner'
import { useCurrentUser } from 'hooks'
import { isUserBot } from 'lib/utils'
import { BottomAppNav } from './Nav/BottomAppNav'
import React from 'react'
import EffectiveNav, { IProps as INavProps } from './Nav/EffectiveNav'
import { AvatarSolicitorNaggerProvider } from './AvatarSolicitorModal/AvatarSolicitorNaggerContext'
import Footer from 'components/Footer'
import { twMerge } from 'tailwind-merge'

interface IProps extends INavProps {
  nav?: 'app' | 'marketing'
  noFooter?: boolean
  noLoggedOutGetStartedButton?: boolean
  className?: string
  children?: React.ReactNode
}

const NavbarLayout: React.FC<IProps> = ({
  nav = 'app',
  children,
  noFooter = false,
  noLoggedOutGetStartedButton = false,
  className,
  ...rest
}) => {
  const { userType } = useCurrentUser()
  const isBot = isUserBot(userType)

  return (
    <EventReminderProvider>
      <AvatarSolicitorNaggerProvider>
        <div className={twMerge('bg-gray-100', className)}>
          <div className="mx-auto">
            <EmailVerificationBanner />

            {!isBot && (
              <EffectiveNav
                useMarketingNav={nav === 'marketing'}
                navRightSideProps={{
                  showLoggedOutGetStartedButton: !noLoggedOutGetStartedButton
                }}
                {...rest}
              />
            )}
            <div className="space-y-20">
              <div className="max-w-screen-2xl mx-auto">{children}</div>
              {!noFooter && <Footer />}
            </div>

            <BottomAppNav />
          </div>
        </div>
      </AvatarSolicitorNaggerProvider>
    </EventReminderProvider>
  )
}

export default NavbarLayout
