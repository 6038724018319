import cls from 'classnames'
import { NextPage } from 'next'
import { useIsLoggedIn } from 'hooks'
import IntroToCollectivesCallout from './Collectives/Callouts/IntroToCollectivesCallout'
import CollectiveTourCallout from './Collectives/Callouts/CollectiveTourCallout'
import ConnectWalloutCallout from './Collectives/Callouts/ConnectWalletCallout'
import IntroToProposalsCallout from './Collectives/Callouts/IntroToProposalsCallout'
import React from 'react'
import FaqCallout from './Collectives/Callouts/FaqCallout'
import UpcomingEventsSidebar from './Collectives/UpcomingEventsSidebar/UpcomingEventsSidebar'
import { UserRecomendations } from 'components/UserRecomendations/UserRecomendations'

interface IProps {
  calloutsHint?: 'proposal'
  layout?: 'collective' | 'generic_sidebar' | 'blank'
  className?: string
  children?: React.ReactNode
}

const ScreenLayout: NextPage<IProps> = ({
  calloutsHint,
  layout = 'collective',
  className,
  children
}) => {
  const isLoggedIn = useIsLoggedIn()

  if (!isLoggedIn || layout === 'blank') {
    return (
      <div className={cls('relative sm:px-4 mt-1 sm:pb-4', className)}>
        {children}
      </div>
    )
  }

  if (layout === 'collective') {
    return (
      <div className="w-full relative flex flex-row space-x-6 sm:px-4 mt-1 sm:pb-4">
        <div className="flex-1 min-h-screen overflow-clip">{children}</div>

        <div
          className="hidden lg:flex flex-col space-y-4 w-96 sticky overflow-visible h-full"
          style={{ top: 70 }}
        >
          <UpcomingEventsSidebar />
          <FaqCallout />
          <ConnectWalloutCallout />
          <CollectiveTourCallout />
          {calloutsHint === 'proposal' && <IntroToProposalsCallout />}
          <IntroToCollectivesCallout />
        </div>
      </div>
    )
  }

  return (
    <div className="flex-1 flex space-x-4 sm:px-4 mt-1 pb-4">
      <div className="flex flex-col flex-1 overflow-auto mb-2 min-h-screen">
        {children}
      </div>

      <div className="hidden lg:flex h-full">
        <div className="bg-white shadow rounded-lg flex-col flex overflow-auto p-4 w-96">
          <UserRecomendations initialLoadCount={3} />
        </div>
      </div>
    </div>
  )
}

export default ScreenLayout
