import { EventFieldsFragment, User } from 'graphql/generated'
import SimpleEventListItem from './events/SimpleEventListItem'

type EngagementType = EventFieldsFragment | User

interface IProps {
  engagement: EngagementType
  limitTitleText?: boolean
  showRsvp?: boolean
  onClick?: CallbackWithParam<EngagementType | undefined>
  showOHLabel?: boolean
  showUserRoleBadge?: boolean
  userId?: string
}

const EngagementListItem: React.FC<IProps> = ({
  engagement,
  limitTitleText = false,
  showRsvp = false,
  showUserRoleBadge = false,
  userId,
  onClick
}) => {
  if (engagement.__typename === 'Event') {
    return (
      <SimpleEventListItem
        event={engagement}
        showRSVP={showRsvp}
        limitTitleText={limitTitleText}
        onClick={onClick}
        showUserRoleBadge={showUserRoleBadge}
        userId={userId}
      />
    )
  }

  return null
}

export default EngagementListItem
