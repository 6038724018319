import { useRouter } from 'next/router'
import { useModalContext } from 'context/ModalContext'
import { useCallback, useMemo, useState } from 'react'
import BaseModal from './BaseModal'
import { User, Event, useReportContentMutation } from 'graphql/generated'
import { useControlledInput } from 'hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'

type TContent = Event | User

interface IOptions {
  navigateTo?: string
  userSearch?: boolean
}

export function useReportContentModal() {
  const { setModalContent } = useModalContext()
  return useCallback(
    (content: TContent, options: IOptions = {}) => {
      setModalContent(
        <ReportContentModal content={content} options={options} />
      )
    },
    [setModalContent]
  )
}

export function ReportContentModal({
  content,
  options: { navigateTo, userSearch }
}: {
  content: TContent
  options: IOptions
}) {
  const { hideModal } = useModalContext()
  const router = useRouter()

  const [text, setText] = useControlledInput()
  const [blockUser, setBlockUser] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { user, idProp } = useMemo(() => {
    switch (content.__typename) {
      case 'User':
        return { user: content, idProp: { profileId: content.id } }
      case 'Event':
        return { user: content.user, idProp: { eventId: content.id } }
      default:
        return userSearch
          ? { user: content, idProp: { profileId: (content as User).id } }
          : {}
    }
  }, [content, userSearch])

  const [reportContent, { loading: saving }] = useReportContentMutation({
    variables: {
      report: {
        text,
        blockUser,
        ...idProp
      }
    },
    onCompleted: () => {
      setShowConfirmation(true)
      if (navigateTo) {
        router.push(navigateTo)
      }
    }
  })

  if (showConfirmation) {
    return <ReportContentConfirmation user={user} blockUser={blockUser} />
  }

  return (
    <BaseModal>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            {`Report Content`}
          </h3>

          <label className="block pt-3">
            <span className="text-gray-700">Additional details</span>

            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              rows={3}
              value={text}
              onChange={setText}
              disabled={saving}
            />
          </label>

          {user && (
            <label className="flex items-center pt-2">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                checked={blockUser}
                onChange={e => setBlockUser(e.target.checked)}
              />
              <span className="ml-2 text-gray-700">
                {`Block ${user.displayName || 'user'} from your feeds?`}
              </span>
            </label>
          )}
        </div>
      </div>

      <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
        <Button
          disabled={saving || !text.trim()}
          onClick={() => reportContent()}
          color={'red'}
          label={saving ? 'Submitting...' : 'Submit'}
        />
        <Button
          disabled={saving}
          onClick={hideModal}
          color={'white'}
          label={'Cancel'}
          className={'mr-3'}
        />
      </div>
    </BaseModal>
  )
}

function ReportContentConfirmation({
  blockUser,
  user
}: {
  blockUser: boolean
  user: Maybe<User>
}) {
  const { hideModal } = useModalContext()

  return (
    <BaseModal>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={'h-6 w-6 text-red-600'}
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {`Report Submitted`}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {`We've received your report & will review it shortly.`}
                {blockUser
                  ? `\n\n${
                      user?.displayName || 'This user'
                    } has been blocked from seeing your profile and content. We'll also remove their content from your feeds.`
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
        <Button onClick={hideModal} color={'white'} label={'Close'} />
      </div>
    </BaseModal>
  )
}
