import { faCompass, faHome } from '@fortawesome/pro-regular-svg-icons'
import { Button, Hidden } from 'components/common'
import Sticky from 'components/Sticky/Sticky'
import { useIsLoggedIn } from 'hooks'
import { ROOT_HOSTNAME } from 'lib/helpers'
import React from 'react'

export const BottomAppNav: React.FC = () => {
  const isLoggedIn = useIsLoggedIn()

  if (!isLoggedIn) {
    return null
  }

  return (
    <Sticky position="bottom" className="z-3000">
      <Hidden smUp>
        <div className="px-10 bg-white border-t border-gray-300 flex justify-between gap-8">
          <Button
            as="a"
            showFocusRing={false}
            size="xl"
            className="flex-1"
            color="textOnly"
            href={`${ROOT_HOSTNAME}/collectives/dashboard`}
            icon={faHome}
          />
          <Button
            as="a"
            showFocusRing={false}
            size="xl"
            className="flex-1"
            color="textOnly"
            href="/events"
            icon={faCompass}
          />
        </div>
      </Hidden>
    </Sticky>
  )
}
