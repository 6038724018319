import BaseCallout from './BaseCallout'
import { Typography } from 'components/common'

function IntroToCollectivesCallout() {
  return (
    <BaseCallout calloutId={'intro-to-collectives'} type={'Video'}>
      <div className="pb-[5px]">
        <iframe
          width="230"
          src="https://www.youtube-nocookie.com/embed/0yRhtscWmaM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography className="pt-[2px] text-[11px] font-light text-right text-gray-500">
          1:30m
        </Typography>
      </div>

      <Typography className="text-base font-normal text-left text-gray-700">
        What is a DAO?
      </Typography>

      <Typography className="text-sm font-light text-left text-gray-600">
        {`Learn everything you need to get started using an Upstream DAO, in less than 3 minutes.`}
      </Typography>
    </BaseCallout>
  )
}

export default IntroToCollectivesCallout
