import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useCallback
} from 'react'
import {
  EventFieldsFragment,
  CurrentEventQueryResult,
  useCurrentEventQuery
} from 'graphql/generated'
import { useFlag, useIsLoggedIn } from 'hooks'
import { useRouter } from 'next/router'
import { noop } from 'lodash'
import BaseModal from 'components/modals/BaseModal'
import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons'
import Link from 'next/link'
import useLocalStorage from 'hooks/useLocalStorage'

const EventReminderContext = createContext({})

export const useEventReminderContext = () => useContext(EventReminderContext)

export function EventReminderProvider({ children }: PropsWithChildren<{}>) {
  const [ignoreEventIds, setIgnoreEventIds] = useLocalStorage<string[]>(
    'ignoreEventIds',
    []
  )
  const isLoggedIn = useIsLoggedIn()
  const router = useRouter()
  const isRouteIgnored = router.pathname.includes('/events/[eventId]')

  const [event, setEvent] = useState<Maybe<EventFieldsFragment>>(null)
  const enable = useFlag('ENABLE_EVENT_REMINDER_WEB', false)

  const onCompleted = useCallback(
    (resp: CurrentEventQueryResult) => {
      if (!resp || !resp.currentEvent) {
        return
      }

      const event = resp.currentEvent
      const eventId = event.id
      if (ignoreEventIds.includes(eventId)) {
        return
      }

      setEvent(event)
    },
    [ignoreEventIds]
  )

  const eventId = event?.id
  const dismiss = () => {
    setEvent(null)
    if (!eventId || ignoreEventIds.includes(eventId)) {
      return
    }

    setIgnoreEventIds([...ignoreEventIds, eventId])
  }

  useCurrentEventQuery({
    skip: !enable || !isLoggedIn || isRouteIgnored,
    ssr: false,
    pollInterval: 60 * 1000,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onError: noop,
    onCompleted
  })

  return (
    <EventReminderContext.Provider value={{}}>
      {children}

      {isLoggedIn && !isRouteIgnored && !!event && (
        <BaseModal>
          <div className="p-6">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <FontAwesomeIcon icon={faCalendar} className="text-green-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {`Video Event: ${event.title}`}
                </h3>

                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {`Your event is about to start. Tap here to join.`}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 flex flex-row justify-center items-center space-x-3">
              <div className="flex-1">
                <Link href={`${event.publicUrl}/video`} passHref>
                  <Button label="Join Event" as="a" fullWidth />
                </Link>
              </div>

              <Button
                label="Dismiss"
                color="white"
                className="flex-1"
                onClick={dismiss}
              />
            </div>
          </div>
        </BaseModal>
      )}
    </EventReminderContext.Provider>
  )
}
