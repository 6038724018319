import Link from 'next/link'
import { FilterableEngagementField } from 'graphql/generated'
import Button from 'components/Button'
import EventListItem from 'components/discover/EventListItem'
import useCollectiveEngagements from 'hooks/collectives/useCollectiveEngagements'
import { useCollectiveContext } from 'context/CollectiveContext'

function UpcomingEventsSidebar() {
  const { collective } = useCollectiveContext()
  const { edges } = useCollectiveEngagements(
    collective?.id,
    [
      FilterableEngagementField.TYPE_SOCIAL,
      FilterableEngagementField.STATUS_NOT_ENDED
    ],
    3
  )

  if (!edges?.length) {
    return null
  }

  return (
    <div className="bg-white p-2 shadow rounded-lg">
      <p className="text-gray-700 font-medium text-sm p-2 pb-4">
        Upcoming Events
      </p>

      <div className="space-y-1">
        {edges.map((edge, index) =>
          edge.node.__typename === 'Event' ? (
            <div key={edge.node.id} className="hover:bg-gray-50 rounded-lg">
              <EventListItem
                event={edge.node}
                index={index}
                showMenu={false}
                showSharing={false}
              />
            </div>
          ) : null
        )}
      </div>

      <div className="p-2 mt-2 flex">
        <Link href={`${collective?.publicUrl}/events`} passHref>
          <Button label="View All" color="white" className="flex-1" />
        </Link>
      </div>
    </div>
  )
}

export default UpcomingEventsSidebar
