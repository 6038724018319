import { EventFieldsFragment } from 'graphql/generated'
import Link from 'next/link'
import { Avatar } from 'components/common/Avatars'
import RSVPButton from './EventButtons/RSVPButton'
import { faSolarSystem } from '@fortawesome/pro-regular-svg-icons'
import language from 'services/Language'
import { useMemo } from 'react'
import Typography from 'components/common/Typography'
import { Chip } from 'components/common'

interface IProps {
  event: EventFieldsFragment
  href?: string
  showRSVP?: boolean
  limitTitleText?: boolean
  showUserRoleBadge?: boolean
  userId?: string
  onClick?: CallbackWithParam<EventFieldsFragment | undefined>
}

const SimpleEventListItem: React.FC<IProps> = ({
  event,
  href,
  showRSVP = false,
  limitTitleText = faSolarSystem,
  showUserRoleBadge = false,
  userId,
  onClick
}) => {
  const { isHost, isSpeaker } = useMemo(() => {
    let isHost = false
    let isSpeaker = -1

    if (showUserRoleBadge) {
      isHost = event.user.id === userId
      isSpeaker = event.speakers.findIndex(sepaker => sepaker.id === userId)
    }

    return { isHost, isSpeaker }
  }, [event.speakers, event.user.id, showUserRoleBadge, userId])

  return (
    <Link
      onClick={() => {
        if (onClick) {
          onClick(event)
        }
      }}
      style={{ display: 'flex', flex: '1' }}
      href={href || event.publicUrl}
    >
      <div
        className="flex flex-col h-full flex-1 justify-between"
        title={limitTitleText ? event.title : undefined}
      >
        <div className={`flex items-center gap-8 mb-${showRSVP ? 2 : 0}`}>
          <div className="flex flex-col items-center mt-1">
            <Avatar
              src={
                event.collective?.thumb && event.collective.thumb?.length > 0
                  ? (event.collective.thumb as string)
                  : undefined
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            {limitTitleText ? (
              <Typography truncate={2} size={'sm'}>
                {event.title}
              </Typography>
            ) : (
              <Typography size={'sm'}>{event.title}</Typography>
            )}
            {isSpeaker != -1 && (
              <Chip label={language.events.speaker} size="sm" color="gray" />
            )}
          </div>

          {showUserRoleBadge && (
            <div className="flex flex-col flex-1 justify-center items-end gap-8">
              {isHost && (
                <Chip label={language.events.host} size="sm" color="blue" />
              )}
              {isSpeaker != -1 && (
                <Chip label={language.events.speaker} size="sm" color="gray" />
              )}
            </div>
          )}
        </div>

        {showRSVP && <RSVPButton event={event} size="sm" />}
      </div>
    </Link>
  )
}

export default SimpleEventListItem
