import Link from 'next/link'
import BaseCallout from './BaseCallout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { CollectiveScreen, useCollectiveFaqsQuery } from 'graphql/generated'

function FaqCallout() {
  const { data } = useCollectiveFaqsQuery({
    variables: {
      screen: CollectiveScreen.COLLECTIVES_LIST,
      limit: 3
    }
  })
  const faqs = data?.collectiveFaqs || []

  if (!faqs.length) {
    return null
  }

  const trackClick = (question: string) => {
    window.analytics.track(`FAQ Clicked: ${question.substring(0, 39)}`)
  }

  return (
    <BaseCallout persistent calloutId={'faq'} type={'FAQ'}>
      <div className={'faq-list-container'}>
        {faqs.map(faq => (
          <div
            key={faq.link}
            className="font-light text-sm text-gray-800 pt-[10px] pb-[10px] hover:text-blue"
            onClick={() => trackClick(faq.question)}
          >
            <Link target="_blank" href={faq.link}>
              <p>{faq.question}</p>
              {!!faq.snippet && <p>{faq.snippet}</p>}
            </Link>
          </div>
        ))}
      </div>

      <Link
        className="mt-[10px] inline-block text-xs font-light text-gray-500 hover:text-[#0C5FE3]"
        target="_blank"
        href="http://guide.upstreamapp.com"
      >
        {`Browse all questions `}
        <FontAwesomeIcon icon={faExternalLink} />
      </Link>
    </BaseCallout>
  )
}

export default FaqCallout
