import BaseCallout from './BaseCallout'
import { Typography } from 'components/common'
import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'
import Button from 'components/Button'

function CollectiveTourCallout() {
  return (
    <BaseCallout calloutId={'collective-tour'} type={'Tour'}>
      <div className="pb-[15px] flex justify-center">
        <Button
          as="a"
          target="_blank"
          href="https://www.youtube.com/watch?v=Q_XyIrZvSq8"
          color="blue"
          label="Start Tour"
          icon={faPlayCircle}
        />
      </div>

      <Typography className="text-base font-normal text-left text-gray-700">
        Take a Tour
      </Typography>

      <Typography className="text-sm font-light text-left text-gray-600">
        {`Take a guided tour around DAOs. We'll show you how to get started, and how you too can become a DAO member today!`}
      </Typography>
    </BaseCallout>
  )
}

export default CollectiveTourCallout
