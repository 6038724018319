import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import { useCurrentUser, useFlag, useIsLoggedIn, usePreference } from 'hooks'
import { useAvatarSolicitorModal } from './AvatarSolicitorModal'

const AvatarSolicitorNaggerContext = createContext({})

export const useAvatarSolicitorNaggerContext = () =>
  useContext(AvatarSolicitorNaggerContext)

export function useSolicitAvatarWhenAppropriate() {
  const [userDataHasLoaded, setUserDataHasLoaded] = useState(false)
  const user = useCurrentUser({ onLoad: () => setUserDataHasLoaded(true) })
  const isLoggedIn = useIsLoggedIn()
  const hasAvatar = !!user.thumb
  const nagged = usePreference('solicitedAvatar', false) as boolean
  const delay = useFlag('delay', 2000)
  const selectAvatar = useAvatarSolicitorModal()

  useEffect(() => {
    if (!isLoggedIn || !userDataHasLoaded || nagged || hasAvatar) {
      return
    }

    const timer = setTimeout(() => selectAvatar(), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [isLoggedIn, userDataHasLoaded, nagged, hasAvatar, delay, selectAvatar])
}

export function AvatarSolicitorNaggerProvider({
  children
}: PropsWithChildren<{}>) {
  useSolicitAvatarWhenAppropriate()

  return (
    <AvatarSolicitorNaggerContext.Provider value={{}}>
      {children}
    </AvatarSolicitorNaggerContext.Provider>
  )
}
