import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEmailVerificationModal } from 'components/modals/EmailVerificationModal'
import { useEmailVerifiedChangedSubscription } from 'graphql/generated'
import { useCurrentUser, useIsLoggedIn } from 'hooks'
import classes from 'classnames'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'

function EmailVerificationBanner() {
  useEmailVerifiedChangedSubscription()
  const showModal = useEmailVerificationModal()

  return (
    <div className="flex justify-center items-center">
      <div
        className={classes(
          'flex justify-center items-center space-x-3 bg-yellow-200 mt-2 px-5 py-1.5 rounded-full',
          'cursor-pointer hover:bg-yellow-300 active:bg-yellow-400'
        )}
        onClick={showModal}
      >
        <p className="text-gray-700 text-center font-light text-sm leading-none">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          {`Please Verify your Email Address `}
        </p>
      </div>
    </div>
  )
}

// avoid opening useEmailVerifiedChangedSubscription when not at all needed
function EmailVerificationBannerGate() {
  const isLoggedIn = useIsLoggedIn()
  const { emailVerified } = useCurrentUser()
  if (!isLoggedIn) {
    return null
  } else if (emailVerified !== false) {
    return null
  }

  return <EmailVerificationBanner />
}

export default EmailVerificationBannerGate
