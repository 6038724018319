import BaseCallout from './BaseCallout'
import { faPlug } from '@fortawesome/pro-regular-svg-icons'
import { useWeb3 } from 'context/Web3Context'
import Button from 'components/Button'
import Typography from 'components/common/Typography'

function ConnectWalloutCallout() {
  const { openSelectWallet, signerAddress } = useWeb3()

  if (signerAddress) {
    return null
  }

  return (
    <BaseCallout calloutId={'collective-tour'} type={'Wallet'}>
      <div className="pb-4 flex content-center">
        <Button
          label={'Connect Wallet'}
          color={'blue'}
          icon={faPlug}
          iconPlacement="before"
          onClick={() => openSelectWallet()}
        />
      </div>

      <Typography className="text-left">Connect Your Wallet</Typography>

      <Typography size="sm" color="lightGray" className="text-left">
        {`You'll need to connect your web3-enabled wallet before you can join a DAO. MetaMask is our favorite, but you can use other wallets too.`}
      </Typography>
    </BaseCallout>
  )
}

export default ConnectWalloutCallout
